import { Controller } from '@hotwired/stimulus'

/**
 * This controller is used to display an image when hovering over an option.
 * The option element has a data-id attribute that corresponds to the data-for attribute of the image.
 */
export default class extends Controller {
  static targets = ['option', 'image', 'logo']

  connect() {
    this.optionTargets.forEach(optionTarget => {
      optionTarget.addEventListener('click', this.displayCheckedImage.bind(this))
    })

    this.optionTargets.forEach(option => {
      option.addEventListener('mouseover', this.displayImageAndLogo.bind(this))
      option.addEventListener('mouseout', this.displayCheckedImageAndLogo.bind(this))
    })
  }

  disconnect() {
    this.optionTargets.forEach(optionTarget => {
      optionTarget.removeEventListener('click', this.displayCheckedImage.bind(this))
    })

    this.optionTargets.forEach(option => {
      option.removeEventListener('mouseover', this.displayImageAndLogo.bind(this))
      option.removeEventListener('mouseout', this.displayCheckedImageAndLogo.bind(this))
    })
  }

  displayImageAndLogo(event) {
    const activeTarget = this.optionTargets.find(
      option => option.querySelector('input[type="radio"]')?.checked
    )

    if (activeTarget) return

    this.displayImage(event)
    this.addClassToLogo(event)
  }

  displayCheckedImageAndLogo() {
    this.displayCheckedImage()
  }

  displayImage(event) {
    const target = event.currentTarget
    const image = this.imageTargets.find(img => img.dataset.for === target.dataset.id)
    if (image) {
      this.imageTargets.forEach(img => img.classList.remove('active'))
      image.classList.add('active')
    }
  }

  displayCheckedImage() {
    this.optionTargets.forEach(option => {
      const input = option.querySelector('input[type="radio"]')

      if (!input) return

      if (input.checked) {
        const image = this.imageTargets.find(img => img.dataset.for === option.dataset.id)
        if (image) {
          this.imageTargets.forEach(img => img.classList.remove('active'))
          image.classList.add('active')

          this.addClassToLogo({ currentTarget: option })
        }
      }
    })
  }

  addClassToLogo(event) {
    const target = event.currentTarget
    const id = target.dataset.id
    const className = `option-${id}`

    this.logoTarget.classList.forEach(cls => {
      if (cls.startsWith('option-')) {
        this.logoTarget.classList.remove(cls)
      }
    })

    this.logoTarget.classList.add(className)
  }
}
