import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    })
    this.observer.observe(this.element)
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.element.click()
        this.observer.unobserve(this.element)
      }
    })
  }
}
